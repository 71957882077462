<template>
    <div class="language-container">

        <div class="language-container-body">
            <img src="../../assets/icons/close_with_shadow.svg" class="language-container-img cancel-responsive" @click="actClosePopup"/>

            <p>{{$t('Choose your Display Language')}}</p>
            <div v-for=" language of languages" :key="language.title" :extra="language.isActive">
                <singleLanguage :language="language" @callbackLang="selectedLanguageArr"></singleLanguage>
            </div>

            <div class="language-button button-primary" @click="actSelectLaguage()">{{$t('PROCEED')}}</div>

        </div>
    </div>
</template>

<script>
const singleLanguage = () => import("@/components/Language/SingleLanguage.vue");
export default {
    data () {
        return {
            languages: [],
            selectedLanguage: [],
        }
    },
    methods: {
        actClosePopup () {
            document.body.style.overflow = "auto"

            this.$emit('cbSwitchLanguage');
        },
        actSelectLaguage () {
            document.body.style.overflow = "auto"

            this.languages.forEach((item) => {
                if (item.isActive == true) {
                    let seletedLanguage = item.local;
                    localStorage.setItem("setDisplayLanguageCode", seletedLanguage);
                    this.$i18n.locale = seletedLanguage;
                    let defaultLanguage = window.PROVIDER_SETUP_CONFIG.defaultLanguage;
                    if(defaultLanguage === seletedLanguage) {
                        window.location.href = window.location.origin + window.location.pathname;
                        return;
                    }
                    if(defaultLanguage === 'eng' && seletedLanguage === 'en') {
                        let newEndPoint = window.location.origin + location.pathname.replace("/hi", "/");
                        window.location.href = newEndPoint.replace(/([^:])(\/\/+)/g, '$1/');
                        return;
                    }
                    if (!window.location.pathname.includes(`/${seletedLanguage}`)) {
                        let newEndPointUpdate = window.location.origin + `/${seletedLanguage}` +  window.location.pathname;
                        window.location.href = newEndPointUpdate.replace(/([^:])(\/\/+)/g, '$1/');
                    }
                }
            });
            this.$emit('cbSwitchLanguage');
        },
        selectedLanguageArr (language) {
            this.languages = this.languages.map((singleLang) => {
                singleLang.isActive = !language.isActive;
                if (singleLang.key == language.key) {
                    singleLang.isActive = language.isActive;
                } 
                return singleLang;
            });
        }
    },
    mounted () {
        this.languages = window.PROVIDER_SETUP_CONFIG.language;
        let langPart = localStorage.getItem("setDisplayLanguageCode");
        this.languages.map((lang) => {
            if (langPart.includes(lang.local)) {
                lang.isActive  = true
            } else {
                lang.isActive  = false

            }
            return lang
            
        })
    },
    components:  {
        singleLanguage
    }
}
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./LanguageModel.scss"
</style>